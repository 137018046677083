
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class GPeoplePicker extends Vue {
  @Prop(String) readonly value!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop({ type: String, default: '' }) readonly label!: string;

  get eventHandlers() {
    return {
      change: this.onChange,
      blur: (e: CustomEvent) => this.$emit('blur', e?.detail?.value),
    };
  }

  onChange(e: CustomEvent) {
    if(this.value != e?.detail?.value) {
      this.$emit('input', e?.detail?.value);
      this.$emit('change', e?.detail?.value);
      return e?.detail?.value;
    }
  }
}
